exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-concept-index-jsx": () => import("./../../../src/pages/concept/index.jsx" /* webpackChunkName: "component---src-pages-concept-index-jsx" */),
  "component---src-pages-eurhythmics-index-jsx": () => import("./../../../src/pages/eurhythmics/index.jsx" /* webpackChunkName: "component---src-pages-eurhythmics-index-jsx" */),
  "component---src-pages-lesson-index-jsx": () => import("./../../../src/pages/lesson/index.jsx" /* webpackChunkName: "component---src-pages-lesson-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-school-index-jsx": () => import("./../../../src/pages/school/index.jsx" /* webpackChunkName: "component---src-pages-school-index-jsx" */),
  "component---src-pages-teacher-index-jsx": () => import("./../../../src/pages/teacher/index.jsx" /* webpackChunkName: "component---src-pages-teacher-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

